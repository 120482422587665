<template>
  <div class="TextAreaInput relative">
    <textarea
      v-model="value"
      class="textarea input resize-y h-auto"
      :autocomplete="autocomplete"
      :disabled="disabled"
      :class="{'cursor-not-allowed': disabled}"
      :rows="rows"
      @focus="focused = true"
      @blur="focused = false"
    />
    <div
      class="absolute top-20 left-0
      type-xs-medium leading-single uppercase
      pointer-events-none select-none
      transition-all"
      :class="{
        'top-8 type-xxs-medium': focused || value?.trim() !== '',
        'text-brightRed': showErrorCombined,
        'text-darker': !showErrorCombined
      }"
    >
      {{ showLabel }}
    </div>

    <div v-if="showErrorCombined" class="absolute top-12 right-0 pointer-events-none select-none">
      <img
        src="/icons/form-error.svg"
        class="w-24 h-24"
        alt=""
      >
    </div>

    <div v-else-if="showSuccess" class="absolute top-12 right-0 pointer-events-none select-none">
      <img
        src="/icons/form-ok.svg"
        class="w-24 h-24"
        alt=""
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui';
const uiStore = useUiStore();

const value = defineModel<string>();

const props = withDefaults(defineProps<{
  label: string;
  showError?: boolean;
  showSuccess?: boolean;
  errors?: string[];
  autocomplete?: string;
  serverName?: string;
  disabled?: boolean;
  rows?: number;
}>(), {
  label: '',
  showError: false,
  showSuccess: false,
  errors: () => [],
  autocomplete: '',
  serverName: '',
  disabled: false,
  rows: 4,
});

const focused = ref(false);

const showErrorCombined = computed(()=> {
  return props.showError || (uiStore.formErrorMsg?.errors && uiStore.formErrorMsg?.errors[props.serverName]);
});

const showLabel = computed(()=> {
  let output = props.label;

  // Server error
  if (uiStore.formErrorMsg?.errors && uiStore.formErrorMsg?.errors[props.serverName]) {
    output += ` - ${uiStore.formErrorMsg.errors[props.serverName][0]}`;
  }
  else if (props.showError && props.errors.length) {
    output += ` - ${props.errors[0]}`;
  }
  return output;
});

</script>

<style scoped lang="postcss">
.textarea {
  border-radius: 0;
  min-height: unset;
  @apply border-b border-t-0 border-r-0 border-l-0 border-light;
}
</style>
