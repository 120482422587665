<template>
  <div class="w-full bg-brightYellow p-12 mt-12">
    <p class="type-xs-medium">
      {{ $t('minicart.crossdocking.title') }}
      <span
        v-if="!showList && !props.expanded"
        class="underline type-xs-medium cursor-pointer"
        @click="showList = true"
      >
        {{ $t('productPage.truncateTextCtaClosed') }}
      </span>
    </p>
    <ul v-if="showList || props.expanded" class="list-disc pl-12 pb-12 pt-12">
      <li class="text-12 pb-8" style="line-height: 18px">
        {{ $t('minicart.crossdocking.contentFirst') }}
      </li>
      <li class="text-12" style="line-height: 18px">
        {{ $t('minicart.crossdocking.contentSecond') }}
      </li>
    </ul>
    <p
      v-if="showList && !props.expanded"
      class="underline type-xs-medium cursor-pointer"
      @click="showList = false"
    >
      {{ $t('productPage.truncateTextCtaOpen') }}
    </p>
  </div>
</template>
<script setup lang="ts">
const props = withDefaults(defineProps<{
  expanded: boolean;
}>(), {
  expanded: false,
});
const showList = ref(false);
</script>
